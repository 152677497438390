import _ from 'lodash';
import { NextPage } from 'next';
import dynamic from 'next/dynamic';
import { NextRouter, useRouter } from 'next/router';
import { ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IMenu } from 'src/interface/layout';
import { MENU_DATA, NAV_DATA } from 'src/redux/navSlice';
import { GET } from 'src/services';
import { timestamp } from 'src/utils/helper';

const Header = dynamic(import('src/components/layout/header'), { ssr: true });
const Footer = dynamic(import('src/components/layout/footer'), { ssr: true });
type TLayout = any & {
  children: ReactNode;
};
const Layout: NextPage<TLayout> = ({ children, sessionSevId }) => {
  const [navData, setNavData] = useState<IMenu[]>([]);
  const router: NextRouter = useRouter();
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      try {
        const res: IMenu[] = await GET({ url: '/api/v1.0/navigations/menu-nav?limit=6', resolvedUrl: 'components/Layout' });
        !_.isEmpty(res) && setNavData(res);
        const find = res?.filter(i => i.nameEng === router?.query?.cid);
        if (!_.isEmpty(find)) {
          dispatch(NAV_DATA(find));
          dispatch(MENU_DATA(res));
        }
      } catch (err: any) {
        console.error(`${timestamp()} ==========> MENU_ERROR : `, err.message);
      }
    })();
  }, []);

  return (
    <>
      <Header data={navData} sessionSevId={sessionSevId} />
      {children}
      <Footer data={navData} />
    </>
  );
};
export default Layout;
